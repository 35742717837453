import React from "react"
import cx from "classnames"
import { Link } from "gatsby"

import Guide from "~/models/guide"
import Image from "~/ui/elements/image/image"

type Props = {
  className?: string
  guide: Guide
}

function GuideCard({ className, guide }: Props) {
  return (
    <Link to={guide.page.url.pathname} className={cx(className)}>
      <div className="shadow-card w-[200px] lg:w-[262px] rounded-xl">
        <div className="overflow-hidden rounded-t-xl isolate">
          <Image
            className="block"
            gatsbyImageData={guide.cover}
            alt={guide.title}
            zoom
          />
        </div>

        <div className="flex justify-center py-3 lg:py-6 text-lg lg:text-2xl text-font-1">
          {guide.title}
        </div>
      </div>
    </Link>
  )
}

export default GuideCard
