import React, { useEffect } from "react"
import { globalHistory } from "@reach/router"

import GameCollection from "~/models/game-collection"
import Game from "~/models/game"
import ArcadeCard from "~/ui/components/game/arcade"
import Link from "~/ui/elements/button/link"

import useArcade from "~/hooks/useArcade"

type ButtonProps = {
  label: string
  link: string
}

type Props = {
  title?: string
  collection: GameCollection
  button?: ButtonProps
  id: string
}

function GameList({ title, collection, button, id }: Props) {
  const { handleDeactivation, handleActivation } = useArcade()

  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (["PUSH", "POP"].indexOf(action) != -1) {
        handleDeactivation()
      }
    })
  }, [])

  function onClick(e: React.MouseEvent<HTMLElement>, game: Game) {
    e.preventDefault()
    handleActivation(game)
  }

  return (
    <div
      id={id}
      className="relative max-w-screen-xl mx-auto px-4 md:px-10 xl:px-20 py-12 md:py-20"
    >
      <h2 className="mb-4 lg:mb-6 text-3xl lg:text-4xl text-font-1 font-bold">
        {title}
      </h2>
      <div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-x-6 gap-y-10">
          {collection.games.map((game: Game) => {
            return (
              <ArcadeCard
                key={game.id}
                game={game}
                onClick={e => onClick(e, game)}
              />
            )
          })}
        </div>

        {button && (
          <div className="mt-14 text-center">
            <Link to={button.link} type="secondary">
              {button.label}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default GameList
