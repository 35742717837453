import React from "react";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import cx from "classnames";

type Props = {
  className?: string;
  filename: string;
  alt: string;
  width?: number;
};

const LocalImage = (props: Props) => {
  const data = useStaticQuery(
    graphql`
      query ImageQuery {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [WEBP]
                )
              }
            }
          }
        }
      }
    `
  );

  const file = data.images.edges.find(
    (n: { node: { relativePath: string } }) => {
      return n.node.relativePath === props.filename;
    }
  );

  if (!file) {
    return null;
  }

  const image = getImage(file.node) as IGatsbyImageData;

  return (
    <GatsbyImage
      className={cx(props.className)}
      image={image}
      alt={props.alt}
      style={props.width ? { width: `${props.width}px` } : undefined}
    />
  );
};

export default LocalImage;
