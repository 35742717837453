import React from "react"
import cx from "classnames"

import Guide from "~/models/guide"
import GuideCard from "~/ui/components/guide/card"
import Carousel from "~/ui/layout/carousel"

type Props = {
  title: string
  description?: string
  guides: Guide[]
  className?: string
}

function GuideList({ title, description, guides, className }: Props) {
  return (
    <section className={cx("overflow-hidden bg-white", className)}>
      <div className="relative max-w-screen-xl mx-auto py-10 md:py-16 md:pl-10 md:pr-0 xl:px-20">
        <div className="px-4 relative mb-6 lg:mb-14  z-10 lg:px-52">
          <h2 className="flex-1 text-3xl font-bold text-font-1 lg:text-6xl text-center">
            {title}
          </h2>

          {description && (
            <p className="flex-1 mt-2 text-sm text-font-2 lg:text-lg text-center">
              {description}
            </p>
          )}
        </div>

        <Carousel itemsPerPage={4} dummyCardClassName="w-[200px] lg:w-[262px]">
          {guides.map(guide => {
            return (
              <GuideCard
                key={guide.id}
                guide={guide}
                className="snap-start flex-shrink-0 pl-4 pr-2 last:pr-4 lg:px-0"
              />
            )
          })}
        </Carousel>
      </div>
    </section>
  )
}

export default GuideList
