import { ContentfulGuide, ContentfulAsset } from "../../types/graphql-types"

import Page from "./page"

export default class Guide {
  id: string
  cover: ContentfulAsset
  title: string
  page: Page
  createdAt: Date

  constructor(guide: ContentfulGuide) {
    this.id = guide.id
    this.cover =
      guide.cover?.localFile?.childImageSharp?.gatsbyImageData ||
      guide.cover?.gatsbyImageData
    this.title = guide.title || ""
    this.page = new Page(guide.page!)
    this.createdAt = new Date(guide.createdAt)
  }
}
