import { ContentfulGameCollection } from "../../types/graphql-types"
import Game from "./game"

export default class GameCollection {
  id: string
  type: string
  games: Game[]

  constructor(collection: ContentfulGameCollection) {
    this.id = collection.id
    this.type = collection.type || ""

    const games = collection.games
      ? collection.games.filter(game => {
          return game !== null
        })
      : []

    this.games = games.map((game: any) => new Game(game))
  }
}
