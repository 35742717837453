import React from "react"
import cx from "classnames"

import TitledSection from "~/ui/layout/titled-section"
import sanitize from "~/helpers/sanitize"

type Props = {
  className?: string
  title: string
  description: string
  items: string[]
}

function Checklist({ className, title, description, items }: Props) {
  return (
    <TitledSection
      title={title}
      description={description}
      background="blue"
      className={cx(className)}
    >
      <div className="flex-1">
        <ul>
          {items.map((item, index) => (
            <li
              key={index}
              className="flex gap-4 items-start mb-4 lg:mb-6 last:mb-0 bg-white rounded-3xl p-4 lg:p-[26px]"
            >
              <img
                src="/images/general/check-circle.svg"
                alt=""
                className="w-6 lg:w-8 flex-shrink-0"
                decoding="async"
              />
              <p
                className="text-font-1"
                dangerouslySetInnerHTML={sanitize(item)}
              ></p>
            </li>
          ))}
        </ul>
      </div>
    </TitledSection>
  )
}

export default Checklist
