import React from "react"
import cx from "classnames"

import sanitize from "~/helpers/sanitize"
import Button from "~/ui/elements/button/link"

type Props = {
  className?: string
  title?: string
  description?: string
  children: JSX.Element | JSX.Element[]
  label: string
  color?: string
  button: any
}

function CTA({
  className,
  title,
  description,
  label,
  button,
  color = "green",
}: Props) {
  return (
    <section className={cx("overflow-hidden py-12 md:py-20", className)}>
      <div className="lg:flex items-start gap-20 relative max-w-screen-xl mx-auto px-4 md:px-10 xl:px-20">
        <div className="flex-1 mx-auto lg:max-w-[738px] relative mb-8 z-10">
          <h2
            className="text-left text-2xl font-bold text-font-1 lg:text-4xl tracking-[-0.025em]"
            dangerouslySetInnerHTML={sanitize(title)}
          ></h2>

          <p
            className="text-left mt-3 text-font-1 text-lg"
            dangerouslySetInnerHTML={sanitize(description)}
          ></p>
        </div>

        <div
          className={cx(
            "flex-1 lg:max-w-[400px] rounded-2xl py-5 px-4 flex gap-2 items-start justify-between",
            {
              "bg-green-300": color == "green",
              "bg-yellow-300": color == "yellow",
            }
          )}
        >
          <p className="text-sm font-bold">{label}</p>
          <Button
            type="primary"
            to={button.link}
            className="shrink-0 max-w-[200px] lg:max-w-none leading-[1.2]"
          >
            {button.label}
          </Button>
        </div>
      </div>
    </section>
  )
}

export default CTA
