import React from "react"
import cx from "classnames"

import TitledSection from "~/ui/layout/titled-section"
import sanitize from "~/helpers/sanitize"

type Props = {
  title: string
  description?: string
  items: string[]
  paragraphs?: string[]
  grid: 4 | 3
}

Sequence.defaultProps = {
  paragraphs: [],
  grid: 3,
}

function Sequence({ title, description, items, paragraphs, grid }: Props) {
  return (
    <TitledSection title={title} description={description}>
      <div
        className={cx(
          "grid grid-cols-1 gap-6 md:gap-10 py-6 px-4 p-10 md:p-10 bg-yellow-300 relative overflow-hidden rounded-lg",
          {
            "md:grid-cols-3": grid == 3,
            "md:grid-cols-4": grid == 4,
          }
        )}
      >
        <img
          src="/images/general/mandala-yellow-2.svg"
          alt=""
          className="w-[220px] absolute -bottom-[36px] -right-[92px] lg:-top-[15px] lg:-right-[31px]"
          decoding="async"
        />

        {items.map((item, index) => {
          return <Step key={index} text={item} index={index} />
        })}

        <img
          src="/images/general/mandala-yellow-1.svg"
          alt=""
          className="w-[300px] lg:w-[704px] absolute bottom-[532px] lg:-bottom-[300px] -left-[60px] lg:-left-[206px]"
          decoding="async"
        />
      </div>

      <React.Fragment>
        {paragraphs && paragraphs.length > 0 && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6 lg:mt-14">
            {paragraphs.map((paragraph, index) => {
              return (
                <p key={index} 
                  className="text-lg text-font-2"
                  dangerouslySetInnerHTML={sanitize(paragraph)}
                  >
                </p>
              )
            })}
          </div>
        )}
      </React.Fragment>
    </TitledSection>
  )
}

type StepProps = {
  text: string
  index: number
  className?: string
}

function Step({ text, index, className }: StepProps) {
  return (
    <div className={cx("relative pb-8 z-10", className)}>
      <div className="mb-4 text-4xl font-bold text-blue-600 leading-none">
        {("0" + (index + 1)).slice(-2)}
      </div>
      <p
        className="text-lg text-font-1"
        dangerouslySetInnerHTML={sanitize(text)}
      ></p>
    </div>
  )
}

export default Sequence
