import React from "react"
import Loadable from "@loadable/component"
import Breadcrumbs from "~/ui/compositions/navigation/breadcrumb"
import Page from "~/models/page"
import GameCollection from "~/models/game-collection"
import Guide from "~/models/guide"

import SEO from "~/ui/helpers/seo"
import Frame from "~/ui/layout/frame"
import CardGridBlock from "~/ui/compositions/generic/card-grid"
import CollapsibleListBlock from "~/ui/compositions/generic/collapsible-list"
import ParagraphsBlock from "~/ui/compositions/generic/paragraphs"
import PageCoverBlock from "~/ui/compositions/generic/page-cover"
import GameListBlock from "~/ui/compositions/game/list"
import SequenceBlock from "~/ui/compositions/generic/sequence"
import GuidesBlock from "~/ui/compositions/guide/list"
import ChecklistBlock from "~/ui/compositions/generic/checklist"
import CTA from "~/ui/compositions/generic/cta"

import { graphql } from "gatsby"
import {
  GuidePageQuery,
  ContentfulPage,
  ContentfulGuide,
  ContentfulGameCollection,
} from "../../../types/graphql-types"

export const query = graphql`
  query GuidePage($id: String, $slug: String) {
    contentfulPage(id: { eq: $id }) {
      ...PageFields
    }
    contentfulGameCollection(type: { eq: $slug }) {
      ...GameCollectionFields
    }
    allContentfulGuide {
      nodes {
        ...GuideFields
      }
    }
  }
`

type Props = {
  data: GuidePageQuery
}

const GuidePage = ({ data }: Props) => {
  const Analytics = Loadable(() => import("../helpers/analytics"))

  const page = new Page(data.contentfulPage as ContentfulPage)
  const gameCollection = new GameCollection(
    data.contentfulGameCollection as ContentfulGameCollection
  )
  const guides = data.allContentfulGuide.nodes.map(guide => {
    return new Guide(guide as ContentfulGuide)
  })

  return (
    <Frame>
      <Analytics page={page} />
      <React.Fragment>
        <SEO page={page} />
        {page.blocks.map(block => (
          <React.Fragment key={block.id}>
            {block.name == "PageCover" && 
            <React.Fragment>
              <PageCoverBlock {...block.props} />
              <div className="mb-2 mt-2">
                <div className="relative justify-between md:flex items-center max-w-screen-xl mx-auto py-7 lg:py-0 px-4 md:px-10 xl:px-20">
                  <Breadcrumbs breadcrumbs={page.breadcrumbs} />
                </div>
              </div>
            </React.Fragment>
            }
            {block.name == "CTA" && <CTA {...block.props} />}
            {block.name == "Paragraphs" && <ParagraphsBlock {...block.props} />}
            {block.name == "GameList" && (
              <GameListBlock
                id="free-games"
                collection={gameCollection}
                {...block.props}
              />
            )}
            {block.name == "CardGrid" && <CardGridBlock {...block.props} />}
            {block.name == "Sequence" && <SequenceBlock {...block.props} />}
            {block.name == "Checklist" && <ChecklistBlock {...block.props} />}
            {block.name == "Guides" && (
              <GuidesBlock guides={guides} {...block.props} />
            )}
            {block.name == "CollapsibleList" && (
              <CollapsibleListBlock {...block.props} />
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    </Frame>
  )
}

export default GuidePage
