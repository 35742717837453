import React from "react"
import cx from "classnames"

import ArcadeGame from "~/models/game"

type Props = {
  game: ArcadeGame
  className?: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

function ArcadeCard({ game, className, onClick }: Props) {
  let asset = game.assets?.find(asset => asset.tags?.indexOf("logo") != -1)

  return (
    <React.Fragment>
      <button
        onClick={onClick}
        className={cx(
          "flex flex-col justify-between border border-gray-400 rounded-lg overflow-hidden",
          className
        )}
      >
        <div className="flex-1 w-full overflow-hidden">
          <img
            src={`${process.env.GATSBY_ARCADE_SERVER}/assets/${asset?.hash}`}
            alt={game.name}
            className="block w-full"
            decoding="async"
          />
        </div>
        <div className="w-full flex justify-center py-4">
          <img
            src={`/images/provider/${game.provider.normalisedName}.svg`}
            alt={game.provider.name}
            className="block flex-shrink-0 z-10 h-[24px] sm:h-[36px]"
            decoding="async"
            height={36}
          />
        </div>
      </button>
    </React.Fragment>
  )
}

export default ArcadeCard
